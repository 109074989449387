"use strict";


window.onload = function() {
    $('.grid').css('opacity', 1);
};

var mainMenu = function() {

    $('.burger-button').on('click', function(){
        $(this).toggleClass('change');
        $('.main-header').toggleClass('show-mobile-menu');
        $('.main-menu .item, .logo').on('click', function(){
            if($('.main-header').hasClass('show-mobile-menu')){
            $('.burger-button').toggleClass('change');
            $('.main-header').removeClass('show-mobile-menu');
            }
        });
    });
};

var containsTable = function() {
    $('.button.contents').on('click', function(e){
        $(this).closest('.product-line').addClass('show-contents');
        e.preventDefault();
    });

    $('.button.back').on('click', function(e) {
        $(this).closest('.product-line').removeClass('show-contents');
        e.preventDefault();
    });
};

var masonryGrid = function() {

    var $grid = $('.grid').masonry({
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        percentPosition: true
    });

    $('.article-items').masonry({
        columnWidth: '.article-sizer',
        itemSelector: '.article-item'
    });

    function filerContents(buttonName) {
        var gridElements = $('.description-section .grid-item');
        gridElements.hide().each(function () {
            if (jQuery.inArray(buttonName, $(this).data('product')) >= 0) {
                $(this).show();
            }
        });

        $grid.masonry();
    }
    filerContents('omega');

    var contentsButtons = $('.contents-buttons-section .button');
    contentsButtons.on('click', function(e) {
        contentsButtons.removeClass('active');
        $(this).addClass('active');
        filerContents($(this).attr('data-show'));
        e.preventDefault();
    });
};

var carousel = function() {

    $('.owl-carousel').owlCarousel({
        items: 1,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplaySpeed: 700,
        navSpeed: 700,
        dots: false,
        loop: true,
        nav: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut'
    });
};

var elementsHeight = function(){
    $('.knowledge-items li').matchHeight();
};

var scrollMagic = function() {

    // set main controler

    var controller = new ScrollMagic.Controller();

    // show or hide fixed menu

    var fixedMainMenu =  new ScrollMagic.Scene({
        triggerElement: '#top',
        triggerHook: '0',
        offset: 150,
        reverse: true
    })
        .on('start', function () {
            $('.main-header').addClass('fixed').fadeIn(1000);
        })
        .on('leave', function () {
            $('.main-header').removeClass('fixed');
        })
        .addTo(controller);

    // loop each product description and trigger animation

    $('.product-line').each(function(){

        var productAnimation =  new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: '0.4',
            offset: 0,
            reverse: true
        })
            .setClassToggle(this,'start-animation')
            .addTo(controller);
    });

    // loop each product description and trigger animation

    $('.benefits-about').each(function(){

        var benefitsAnimation =  new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: '.95',
            offset: 0,
            reverse: true
        })
            .setClassToggle(this,'start-animation')
            .addTo(controller);
    });

    // knowledge animation trigger

    var knowledgeAnimation =  new ScrollMagic.Scene({
        triggerElement: '.knowledge',
        triggerHook: '.8',
        offset: 0,
        reverse: true
    })
        .setClassToggle('.knowledge','start-animation')
        .addTo(controller);

    // short section contact fix

    var contactFix =  new ScrollMagic.Scene({
        triggerElement: '.main-footer',
        triggerHook: '1',
        offset: 0,
        reverse: true
    })
        .setClassToggle('.contact-link','active-contact')
        .on('enter', function(e){
            hashtagChange('kontakt');
        })
        .addTo(controller);

    var offsetValue = -75;

    $.scrollIt({
        upKey: 38,             // key code to navigate to the next section
        downKey: 40,           // key code to navigate to the previous section
        easing: 'linear',      // the easing function for animation
        scrollTime: 1000,       // how long (in ms) the animation takes
        activeClass: 'active', // class given to the active nav element
        onPageChange: hashtag,

        // function(pageIndex) that is called when page is changed
        topOffset: offsetValue // offset (in px) for fixed top navigation
    });

    function hashtagChange(hashtag) {
        if (history.pushState) {
            history.pushState(null, null, '#' + hashtag);
        } else {
            location.hash = '#' + hashtag;
        }
    }

    function hashtag(pageIndex) {

        switch (pageIndex) {
            case '0':
                history.pushState("", document.title, window.location.pathname);
                $('.products-link').removeClass('active-product');
                break;
            case '1':
                hashtagChange('o-marce');
                $('.products-link').removeClass('active-product');
                break;
            case '2':
                hashtagChange('produkty-omega-kic-kic');
                $('.products-link').addClass('active-product');
                break;
            case '3':
                hashtagChange('produkty-vita-kic-kic');
                $('.products-link').addClass('active-product');
                break;
            case '4':
                hashtagChange('produkty-multinki-kic-kic');
                $('.products-link').addClass('active-product');
                break;
            case '5':
                hashtagChange('produkty-septusie-kic-kic');
                $('.products-link').addClass('active-product');
                break;
            case '6':
                hashtagChange('skladniki');
                $('.products-link').removeClass('active-product');
                break;
            case '7':
                hashtagChange('kontakt');
                $('.products-link').removeClass('active-product');
                break;
        }
    }
};

var cookies = function() {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }

    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').slideToggle('slow', function () {
            $.cookie('giodoCookies', '1', {expires: 365});
        });
    });
};

var textOrphans = function() {
    $('p, .grid li').each(function() {
        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g,"$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

$(function() {
    mainMenu();
    containsTable();
    carousel();
    masonryGrid();
    elementsHeight();
    scrollMagic();
    cookies();
    textOrphans();
});